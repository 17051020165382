import React from "react";

export default class EmailSignup extends React.Component {
  componentDidMount() {
    window.fnames = new Array();
    window.ftypes = new Array();
    window.fnames[0] = "EMAIL";
    window.ftypes[0] = "email";
    window.fnames[1] = "FNAME";
    window.ftypes[1] = "text";
    window.fnames[2] = "LNAME";
    window.ftypes[2] = "text";
    // var $mcj = jQuery.noConflict(true);
  }
  render() {
    return (
      <div>
        <div id="mc_embed_signup" className="mailchimp-form">
          <form
            action="https://gwu.us1.list-manage.com/subscribe/post?u=4e82f91df18be9522a205eae6&amp;id=460994c238"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <div className="row">
                <div className="form-group col-md-6">
                  <input
                    placeholder="First Name"
                    type="text"
                    name="FNAME"
                    className="form-control"
                    id="mce-FNAME"
                  />
                </div>
                <div className="form-group col-md-6">
                  <input
                    placeholder="Last Name"
                    type="text"
                    name="LNAME"
                    className="form-control"
                    id="mce-LNAME"
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="EMAIL"
                  className="form-control required email"
                  id="mce-EMAIL"
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                />
              </div>
              <div
                style={{ position: "absolute", left: -5000 }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_4e82f91df18be9522a205eae6_460994c238"
                  tabIndex="-1"
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="btn mailchimp-submit"
                />
              </div>
            </div>
          </form>
        </div>
        <script
          type="text/javascript"
          src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
        />
      </div>
    );
  }
}
