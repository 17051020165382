import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Carousel from "nuka-carousel";
import { PagingDots } from "nuka-carousel/lib";
import { navigate } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import EmailSignup from "../components/email-signup";

class IndexPage extends React.Component {
  componentDidMount() {
    this.resetCarousel(0);
    const upcomingProgram = this.props.data.highlightedPrograms.edges
      .map(p => p.node.frontmatter)
      .find(program => program.enabled && program.eventbrite_id);
    if (window && window.EBWidgets && upcomingProgram) {
      window.EBWidgets.createWidget({
        widgetType: "checkout",
        eventId: upcomingProgram.eventbrite_id,
        modal: true,
        modalTriggerElementId: "eventbrite-button",
        onOrderComplete: () => {
          navigate("/see-you-soon");
        },
      });
    }
  }

  resetCarousel(count) {
    if (this.carousel) {
      this.carousel.setDimensions();
      this.carousel.forceUpdate();
      if (count < 10) {
        setTimeout(() => this.resetCarousel(count + 1), 100 * count);
      }
    }
  }

  render() {
    const { data } = this.props;
    const programData = data.programs.edges.map(p => p.node.frontmatter);
    const selectedArchivePrograms = [];
    while (selectedArchivePrograms.length < Math.min(4, programData.length)) {
      const idx = Math.round(Math.random() * (programData.length - 1));
      const selectedProgram = programData[idx];
      if (!selectedArchivePrograms.includes(selectedProgram)) {
        selectedArchivePrograms.push(selectedProgram);
      }
    }
    const highlightedProgramData = data.highlightedPrograms.edges
      .map(p => p.node.frontmatter)
      .filter(program => program.enabled);
    highlightedProgramData.forEach(program => {
      console.log(program.date);
      console.log(program.date > new Date());
    });
    const hasUpcomingProgram =
      highlightedProgramData.filter(
        program =>
          !!program.eventbrite_id || Date.parse(program.date) > new Date()
      ).length === 1;
    return (
      <Layout>
        <SEO
          title="The Kalb Report"
          keywords={[`kalb`, `kalb report`, `the kalb report`]}
        />
        <section className="hero-area">
          <div className="container">
            <div className="row">
              <div className="col-md-7 offset-md-5">
                <h3 className="upcoming-program">
                  {hasUpcomingProgram ? "Upcoming Program" : "Recent Programs"}
                </h3>
              </div>
            </div>
            <Carousel
              ref={carousel => (this.carousel = carousel)}
              autoplay={highlightedProgramData.length > 1}
              autoplayInterval={10000}
              wrapAround={true}
              renderCenterLeftControls={() => null}
              renderCenterRightControls={() => null}
              renderBottomCenterControls={props => {
                return (
                  <div>
                    {/* <a href="#" onClick={props.previousSlide}>
                    Prev
                  </a> */}
                    {highlightedProgramData.length > 1 ? (
                      <PagingDots {...props} />
                    ) : null}
                    {/* <a href="#" onClick={props.nextSlide}>
                    Next
                  </a> */}
                  </div>
                );
              }}
            >
              {highlightedProgramData.map(highlightedProgram => {
                return (
                  <div className="row" key={highlightedProgram.date.toString()}>
                    <div className="col-md-5">
                      <div className="video-player">
                        {highlightedProgram.photo ? (
                          <Img
                            fluid={
                              highlightedProgram.photo.childImageSharp.fluid
                            }
                            fadeIn={false}
                            style={{
                              border: "3px solid #707d8f",
                              maxWidth: 439,
                            }}
                          />
                        ) : null}
                        {highlightedProgram.photo_caption ? (
                          <p className="about-the-program">
                            {highlightedProgram.photo_caption}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="block">
                        <div className="program-promo">
                          <h2>{highlightedProgram.title}</h2>
                          <h4>
                            {new Date(
                              Date.parse(highlightedProgram.date)
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              timeZone: "America/Los_Angeles",
                            })}
                          </h4>
                          <div
                            className="about-the-program"
                            dangerouslySetInnerHTML={{
                              __html: highlightedProgram.description,
                            }}
                          />

                          {highlightedProgram.eventbrite_id ? (
                            <button
                              id="eventbrite-button"
                              className="btn free-tickets-button"
                            >
                              Get Free Tickets
                            </button>
                          ) : highlightedProgram.link ? (
                            <a
                              href={highlightedProgram.link.url}
                              className="btn free-tickets-button"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {highlightedProgram.link.link_title}
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </section>
        <section
          className="about-2 section bg-gray about-the-program-section"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <h2>About the Series</h2>
              </div>
              <div className="col-12 col-md-8">
                <p>
                  The Kalb Report, moderated by Marvin Kalb, is jointly produced
                  by the National Press Club Journalism Institute, The George
                  Washington University, Harvard University, University of
                  Maryland Global Campus (UMGC), the Philip Merrill College of
                  Journalism at the University of Maryland, and Gaylord College
                  of Journalism &amp; Mass Communication at the University of
                  Oklahoma. The series is underwritten by a grant from Ethics
                  and Excellence in Journalism Foundation.
                </p>
                <Link to="/about-the-series">Read more…</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="about-2 section bg-gray archive-section" id="about">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <h2>From the Archive</h2>
              </div>
              {selectedArchivePrograms.map(program => {
                return (
                  <div className="col-12 col-md-2" key={program.slug}>
                    <Link to={`/archive#${program.slug}`}>
                      {program.photo ? (
                        <Img
                          fixed={program.photo.childImageSharp.fixed}
                          fadeIn={false}
                        />
                      ) : null}
                      {program.title}
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div
                className="col-12 col-md-8  offset-md-4"
                style={{ marginTop: 20 }}
              >
                <Link to="/archive">See full archive…</Link>
              </div>
            </div>
          </div>
        </section>
        <section
          className="about-2 section bg-gray newsletter-section"
          id="about"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4">
                <h2>Stay Connected</h2>
              </div>
              <div className="col-12 col-md-8">
                <p>
                  Subscribe to our mailing list to be among the first to know
                  about upcoming programs!
                </p>
                <EmailSignup />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    programs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(programs)/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            photo {
              childImageSharp {
                fixed(width: 160, height: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    highlightedPrograms: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/(highlightedPrograms)/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            description
            photo {
              childImageSharp {
                fluid(maxWidth: 439) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link {
              link_title
              url
            }
            enabled
            eventbrite_id
            photo_caption
          }
        }
      }
    }
  }
`;

export default IndexPage;
